var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[(_vm.error)?_c('v-card-text',{staticClass:"mb-0"},[_c('v-alert',{staticClass:"mb-0",attrs:{"dense":"","outlined":"","border":"left","type":"error"}},[_vm._v(" "+_vm._s(_vm.error.message)+" ")])],1):_vm._e(),_c('v-card-text',[_c('m-widget-container-form',{model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_c('m-coord-picker',{attrs:{"label":"Map Center"},model:{value:(_vm.value.center),callback:function ($$v) {_vm.$set(_vm.value, "center", $$v)},expression:"value.center"}}),_c('field-card',{attrs:{"label":"Orientation"}},[_c('v-row',{staticClass:"wrap"},[_c('v-col',{staticClass:"grow",staticStyle:{"min-width":"300px"}},[_c('v-switch',{attrs:{"label":"Show Orientation"},model:{value:(_vm.value.options.showOrientation),callback:function ($$v) {_vm.$set(_vm.value.options, "showOrientation", $$v)},expression:"value.options.showOrientation"}})],1),(_vm.value.options.showOrientation)?_c('v-col',{staticClass:"grow",staticStyle:{"min-width":"300px"}},[_c('v-slider',{attrs:{"label":"Orientation Angle","inverse-label":"","hint":"0 => North, 90 => East, 180 => South, 270 => West","persistent-hint":"","min":"0","max":"359","thumb-label":"always"},model:{value:(_vm.value.options.orientationAngle),callback:function ($$v) {_vm.$set(_vm.value.options, "orientationAngle", $$v)},expression:"value.options.orientationAngle"}})],1):_vm._e()],1)],1),_c('m-array-form',{attrs:{"name":"Category","plural-name":"Categories","item-photo":"icon"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var editItem = ref.editItem;
return [_c('validation-provider',{attrs:{"rules":"required","name":"Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Title","required":"","error-messages":errors},model:{value:(editItem.title),callback:function ($$v) {_vm.$set(editItem, "title", $$v)},expression:"editItem.title"}})]}}],null,true)}),_c('v-hover',{staticClass:"mb-8",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{style:({ borderColor: hover ? 'black' : null }),attrs:{"outlined":""}},[_c('v-subheader',[_vm._v(" Icon ")]),_c('v-card-text',{staticClass:"pt-0"},[_c('asset-manager',{model:{value:(editItem.icon),callback:function ($$v) {_vm.$set(editItem, "icon", $$v)},expression:"editItem.icon"}})],1)],1)]}}],null,true)})]}}]),model:{value:(_vm.value.categories),callback:function ($$v) {_vm.$set(_vm.value, "categories", $$v)},expression:"value.categories"}}),_c('m-array-form',{attrs:{"name":"Layer","item-photo":"icon"},scopedSlots:_vm._u([{key:"item.subtitle",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.value.categories.find(function (x) { return x.id === item.category; }).title)+" ")]}},{key:"default",fn:function(ref){
var editItem = ref.editItem;
return [_c('validation-provider',{attrs:{"rules":"required","name":"Category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.value.categories,"error-messages":errors,"label":"Category","outlined":"","item-text":"title","item-value":"id"},model:{value:(editItem.category),callback:function ($$v) {_vm.$set(editItem, "category", $$v)},expression:"editItem.category"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Title","required":"","error-messages":errors},model:{value:(editItem.title),callback:function ($$v) {_vm.$set(editItem, "title", $$v)},expression:"editItem.title"}})]}}],null,true)}),_c('v-hover',{staticClass:"mb-8",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{style:({ borderColor: hover ? 'black' : null }),attrs:{"outlined":""}},[_c('v-subheader',[_vm._v(" Icon ")]),_c('v-card-text',{staticClass:"pt-0"},[_c('asset-manager',{model:{value:(editItem.icon),callback:function ($$v) {_vm.$set(editItem, "icon", $$v)},expression:"editItem.icon"}})],1)],1)]}}],null,true)})]}}],null,true),model:{value:(_vm.value.layers),callback:function ($$v) {_vm.$set(_vm.value, "layers", $$v)},expression:"value.layers"}})],1),_c('v-divider'),_c('v-card-text',[_c('m-array-form',{attrs:{"name":"Marker","plural-name":"Markers"},scopedSlots:_vm._u([{key:"item.subtitle",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.value.layers.find(function (x) { return x.id === item.layer; }).title)+" ")]}},{key:"default",fn:function(ref){
var editItem = ref.editItem;
return [_c('validation-provider',{attrs:{"rules":"required","name":"Layer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.value.layers,"error-messages":errors,"label":"Layer","outlined":"","item-text":"title","item-value":"id"},model:{value:(editItem.layer),callback:function ($$v) {_vm.$set(editItem, "layer", $$v)},expression:"editItem.layer"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Title","required":"","error-messages":errors},model:{value:(editItem.title),callback:function ($$v) {_vm.$set(editItem, "title", $$v)},expression:"editItem.title"}})]}}],null,true)}),_c('m-coord-picker',{model:{value:(editItem.coordinates),callback:function ($$v) {_vm.$set(editItem, "coordinates", $$v)},expression:"editItem.coordinates"}})]}}],null,true),model:{value:(_vm.value.markers),callback:function ($$v) {_vm.$set(_vm.value, "markers", $$v)},expression:"value.markers"}}),_c('field-card',{attrs:{"label":"Design"}},[_c('m-color-input',{attrs:{"label":"Categories Background Color"},model:{value:(_vm.value.options.categoriesBackground),callback:function ($$v) {_vm.$set(_vm.value.options, "categoriesBackground", $$v)},expression:"value.options.categoriesBackground"}})],1),_c('field-card',{attrs:{"label":"Options"}},[_c('v-row',{staticClass:"flex-wrap"},[_c('v-col',{staticClass:"grow",staticStyle:{"min-width":"300px"}},[_c('v-switch',{attrs:{"label":"Store Map Tiles Offline"},model:{value:(_vm.value.options.useOfflineStorage),callback:function ($$v) {_vm.$set(_vm.value.options, "useOfflineStorage", $$v)},expression:"value.options.useOfflineStorage"}})],1),(_vm.value.options.useOfflineStorage)?_c('v-col',{staticClass:"grow",staticStyle:{"min-width":"300px"}},[_c('v-slider',{attrs:{"label":"Offline Map Radius (km)","inverse-label":"","hint":"Tiles that are inside this radius of the map center will be stored for offline use.","persistent-hint":"","min":"100","max":"500","thumb-label":"always"},model:{value:(_vm.value.options.offlineStorageRadiusKm),callback:function ($$v) {_vm.$set(_vm.value.options, "offlineStorageRadiusKm", $$v)},expression:"value.options.offlineStorageRadiusKm"}})],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }