<template>
  <v-card flat>
    <v-card-text
      v-if="error"
      class="mb-0"
    >
      <v-alert
        dense
        outlined
        border="left"
        type="error"
        class="mb-0"
      >
        {{ error.message }}
      </v-alert>
    </v-card-text>
    <v-card-text>
      <m-widget-container-form v-model="value" />
      <m-coord-picker
        v-model="value.center"
        label="Map Center"
      />
      <field-card label="Orientation">
        <v-row class="wrap">
          <v-col
            style="min-width: 300px;"
            class="grow"
          >
            <v-switch
              v-model="value.options.showOrientation"
              label="Show Orientation"
            />
          </v-col>
          <v-col
            v-if="value.options.showOrientation"
            style="min-width: 300px;"
            class="grow"
          >
            <v-slider
              v-model="value.options.orientationAngle"
              label="Orientation Angle"
              inverse-label
              hint="0 => North, 90 => East, 180 => South, 270 => West"
              persistent-hint
              min="0"
              max="359"
              thumb-label="always"
            />
          </v-col>
        </v-row>
      </field-card>
      <m-array-form
        v-model="value.categories"
        name="Category"
        plural-name="Categories"
        item-photo="icon"
      >
        <template #default="{ editItem }">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Title"
          >
            <v-text-field
              v-model="editItem.title"
              outlined
              label="Title"
              required
              :error-messages="errors"
            />
          </validation-provider>
          <v-hover
            v-slot="{ hover }"
            class="mb-8"
          >
            <v-card
              outlined
              :style="{ borderColor: hover ? 'black' : null }"
            >
              <v-subheader>
                Icon
              </v-subheader>
              <v-card-text class="pt-0">
                <asset-manager v-model="editItem.icon" />
              </v-card-text>
            </v-card>
          </v-hover>
        </template>
      </m-array-form>
      <m-array-form
        v-model="value.layers"
        name="Layer"
        item-photo="icon"
      >
        <template #[`item.subtitle`]="{ item }">
          {{ value.categories.find(x => x.id === item.category).title }}
        </template>
        <template #default="{ editItem }">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Category"
          >
            <v-select
              v-model="editItem.category"
              :items="value.categories"
              :error-messages="errors"
              label="Category"
              outlined
              item-text="title"
              item-value="id"
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Title"
          >
            <v-text-field
              v-model="editItem.title"
              outlined
              label="Title"
              required
              :error-messages="errors"
            />
          </validation-provider>
          <v-hover
            v-slot="{ hover }"
            class="mb-8"
          >
            <v-card
              outlined
              :style="{ borderColor: hover ? 'black' : null }"
            >
              <v-subheader>
                Icon
              </v-subheader>
              <v-card-text class="pt-0">
                <asset-manager v-model="editItem.icon" />
              </v-card-text>
            </v-card>
          </v-hover>
        </template>
      </m-array-form>
    </v-card-text>
    <v-divider />
    <v-card-text>
      <m-array-form
        v-model="value.markers"
        name="Marker"
        plural-name="Markers"
      >
        <template #[`item.subtitle`]="{ item }">
          {{ value.layers.find(x => x.id === item.layer).title }}
        </template>
        <template #default="{ editItem }">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Layer"
          >
            <v-select
              v-model="editItem.layer"
              :items="value.layers"
              :error-messages="errors"
              label="Layer"
              outlined
              item-text="title"
              item-value="id"
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Title"
          >
            <v-text-field
              v-model="editItem.title"
              outlined
              label="Title"
              required
              :error-messages="errors"
            />
          </validation-provider>
          <m-coord-picker
            v-model="editItem.coordinates"
          />
        </template>
      </m-array-form>
      <field-card label="Design">
        <m-color-input
          v-model="value.options.categoriesBackground"
          label="Categories Background Color"
        />
      </field-card>
      <field-card label="Options">
        <v-row class="flex-wrap">
          <v-col
            style="min-width: 300px;"
            class="grow"
          >
            <v-switch
              v-model="value.options.useOfflineStorage"
              label="Store Map Tiles Offline"
            />
          </v-col>
          <v-col
            v-if="value.options.useOfflineStorage"
            style="min-width: 300px;"
            class="grow"
          >
            <v-slider
              v-model="value.options.offlineStorageRadiusKm"
              label="Offline Map Radius (km)"
              inverse-label
              hint="Tiles that are inside this radius of the map center will be stored for offline use."
              persistent-hint
              min="100"
              max="500"
              thumb-label="always"
            />
          </v-col>
        </v-row>
      </field-card>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'MMapForm',
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    error: null
  })
}
</script>
